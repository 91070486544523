import './global';
import './searchFieldSpecs';
import './sharedSpecs';
import './globalMixins';
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import lodash from 'lodash';
import {createApp, h} from 'vue';
import bus from '@/shared/event-bus.js';
import 'echarts/lib/component/tooltip';

import '@fortawesome/fontawesome-free/css/all.css';
// consumption model....see https://bootstrap-vue.org/docs#using-module-bundlers for other options including importing scss versions...
import { BootstrapVueNext } from 'bootstrap-vue-next';
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";

import './filters';

/*-- Google Analytics --*/
let measurementId = '';
if (window.location.hostname === 'reporter.nih.gov') {
    measurementId = 'G-VT0D0WJCKB';
} else if (window.location.hostname === 'reporter.stage.era.nih.gov') {
    measurementId = 'G-WDZSG9R7G4';
} else {
    measurementId = 'G-YL4VDGXB51';
}

var script = document.createElement('script');
script.src = 'https://www.googletagmanager.com/gtag/js?id='+measurementId;
document.head.appendChild(script);
script = document.createElement('script');
script.innerHTML = "window.dataLayer = window.dataLayer || []; \
	function gtag(){dataLayer.push(arguments);}	\
	gtag('js', new Date());	 \
	gtag('config', '" + measurementId + "');";
document.head.appendChild(script);

export const EventBus = bus;
export const app = createApp({
    router,
    store,
    render: () => ( h(App)),
    async created () {
        var requestedURLs = [
            'reporter_api_base_url',
            'reporter_feedback_url',
            'report_base_url',
            'reporter_nih_url',
            'reporter_google_map_api_key',
            'reporter_project_search_error_message',
            'reporter_project_search_max_core_projects_limit'
        ];

        await this.$store.dispatch('getAppSettingsPropertyByNameAction', requestedURLs);
        EventBus.$emit('get:appsettings');
    },
}).use(lodash).use(router).use(BootstrapVueNext).use(store).mount('#app');

app.$options.$filters = window.filters;
